import { PortabilityStatus } from "./portability-status.enum";

export class PortabilityProposal {
    idProdutor?: string
    idSistemaDestino?: string
}

export class Portability {
    idBlockchain?: string;
    idSistemaOrigem?: string
    idProdutor?: string
    idSistemaDestino?: string
    id?: string
    status?: PortabilityStatus
    dataCriacao?: Date
    dataUltimaAtualizacao?: Date
}