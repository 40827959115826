
import { dateTimeFilter, underlineToSpace } from "@/filters/custom-filters";
import { Portability } from "@/models/portability/portabilty.model";
import { defineComponent } from "@vue/runtime-core";

const DetailPortability = defineComponent({
  props: {
    portability: Portability,
  },
  methods: {
    dateTimeFilter,
    underlineToSpace,
  },
})
export default DetailPortability;
