
import { NDataTable, NSelect, NSpace } from "naive-ui";
import { Portability } from "@/models/portability/portabilty.model";
import HeaderContent from "@/components/template/HeaderContent.vue";
import DetailPortability from "@/components/portability/DetailPortability.vue";
import portabilityService from "@/services/portability.service";
import { dateFilter, underlineToSpace } from "../filters/custom-filters";
import { defineComponent, h, reactive } from "@vue/runtime-core";
import producerService from "@/services/producer.service";
import { createDebounce } from "@/common/utils";
import GlobalEvent from "@/common/global-event";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (portability: any) => {
        return h(DetailPortability, { portability });
      },
    },
    {
      title: "Data",
      key: "dataCriacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render(row: any) {
        return dateFilter(row.dataCriacao);
      },
    },
    {
      title: "Sistema Origem",
      key: "idSistemaOrigem",
      sorter: "default",
    },
    {
      title: "Sistema Destino",
      key: "idSistemaDestino",
      sorter: "default",
    },
    {
      title: "Status",
      key: "status",
      sorter: "default",
      render: (portability: Portability) =>
        underlineToSpace(portability?.status?.toString()),
    },
  ];
};

const paginationReactive = reactive({
  page: 1,
  pageSize: 10,
  showSizePicker: false,
  onChange: (page: any) => {
    paginationReactive.page = page;
  },
});

const SearchPortability = defineComponent({
  name: 'SearchPortability',
  components: { HeaderContent, NDataTable, NSelect, NSpace },

  data() {
    return {
      form: { idBlockchain: "", currentProducerID: "" },
      portabilities: Array<Portability>(),
      portabilitiesOriginal: null as any,
      listProducers: [],
      columns: createColumns(),
      pagination: paginationReactive,
    };
  },

  methods: {
    dateFilter,
    underlineToSpace,
    idBlockchainDebouncer: createDebounce(),

    getProducers() {
      GlobalEvent.emit("appLoading", true);
      producerService
        .getAll()
        .then((response) => {
          this.listProducers = response.data.map((e: any) => {
            return {
              label: e.data.nome,
              value: e.data.idBlockchain,
            };
          });
        })
        .finally(() => GlobalEvent.emit("appLoading", false));
    },

    clearFilters() {
      this.form.currentProducerID = "";
      this.form.idBlockchain = "";
      this.portabilities = [];
    },

    searchPortabilities() {
      if (this.form.currentProducerID == "") {
        GlobalEvent.emit("dialog", {
          icon: "bi bi-exclamation-circle",
          messageLabel: "Selecione um produtor para filtrar",
          btnSuccessLabel: "Entendi"
        });
      }

      GlobalEvent.emit("appLoading", true);
      portabilityService
        .getPortabilityByIdProducer(this.form.currentProducerID)
        .then((response) => {
          this.portabilities = response.data;
          this.portabilitiesOriginal = response.data;
        })
        .finally(() => {
          GlobalEvent.emit("appLoading", false);
        });
    },

    onIdBlockchainChange() {
      const id: string = this.form.idBlockchain;

      this.portabilities = this.portabilitiesOriginal.filter(
        (portability: any) => portability?.idBlockchain?.indexOf(id) > -1
      );
    },
  },

  beforeMount() {
    this.getProducers();
  },
})
export default SearchPortability;
